import React, { useEffect, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin, Typography } from "antd";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import Player from "../components/Player";

export default function Minimixes() {
  const navigate = useNavigate();
  const classes = useStyle();

  const [currentTrack, setCurrentTrack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mixes, setMixes] = useState([]);

  const fetchMixes = async () => {
    let data = [
      {
        audio_url:
          "https://res.cloudinary.com/dsqyuhnmx/video/upload/v1724218019/audio/MTV-Elevator-minimix.mp3",
        created_at: "2023-07-23T08:38:26.820654+00:00",
        id: "95317a82-6e6c-44c7-8448-7b47fe57f6e1",
        name: "MTV Elevator",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/mtv-FHigV3E8Aizbth1bS4amreqgmKNuWo.jpeg",
      },
      {
        audio_url:
          "https://res.cloudinary.com/dsqyuhnmx/video/upload/v1724218025/audio/Dancefloor-Hits-minimix.mp3",
        created_at: "2023-07-23T08:35:06.825026+00:00",
        id: "1da7df37-c18d-4892-b29d-6c044b47b4b8",
        name: "Dancefloor Hits",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/danceflooe%20hits-diAjFHEmWolysIP9EDw63siCI2QJMV.jpg",
      },
      {
        audio_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/audio/UK-house-party-minimix-yWxN9SmzVzOa3KVIP7barP8ufBqMJT.mp3",
        created_at: "2023-07-23T08:42:17.002507+00:00",
        id: "f47f1811-16f0-453a-9091-bac06d5ee379",
        name: "UK House Party",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/uk%20house%20party-xOmZfmLV8EHr0JxK03nlKnSkiloquV.jpeg",
      },
      {
        audio_url:
          "http://res.cloudinary.com/dsqyuhnmx/video/upload/v1724217752/audio/jimmy.mp3",
        created_at: "2023-07-23T08:41:06.001642+00:00",
        id: "5a5535cf-50ed-4bb8-8358-29c4657545d7",
        name: "H.O.T",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/sunsunsun-06Zy5gZjyTeJBQYDggbPjjtSbyuSBu",
      },
      {
        audio_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/audio/Midnight-Cocktail-minimix-mFvG3w1jOgkhHBdf49bFqfIOE4DckY.mp3",
        created_at: "2023-07-23T08:37:37.778304+00:00",
        id: "1b735087-608a-4b24-832e-2a1b0953ad36",
        name: "Midnight Cocktail",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/midnight%20cocktail-ypbd1mxNDMZ6bDSCLkdHVxtfQYVhfh.jpeg",
      },
      {
        audio_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/audio/HOUSE-ROLLERS-minimix-LyIvQAHVSVGnnfDV9v5Oni8JJEHB1U.mp3",
        created_at: "2023-07-23T08:36:33.40136+00:00",
        id: "7cb7bc2c-8f37-4c81-9467-7b42073e8d24",
        name: "House Rollers",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/house%20rollers-D9OuKGEFTjBveVyS9kTrl46tsCUt0U.jpeg",
      },
      {
        audio_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/audio/Sorry-Caller-LaZMWOBUbtTGMo1vnR2ufiN4SE00bs.mp3",
        created_at: "2023-07-23T08:40:12.416467+00:00",
        id: "1a437c9a-285b-471c-97df-008f81126dfa",
        name: "Sorry Caller No One Home",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/sorry%20caller%20no%20one%20home-apck757FdEMm6cidjh3GA3I25PVJ69.jpeg",
      },
      {
        audio_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/audio/villalobos-impersonator-O772EBv0UW9VQsbhEaphamc935fsAL.mp3",
        created_at: "2023-07-23T08:43:57.034+00:00",
        id: "2e48da26-dbfe-44eb-9bba-25d13da0a21a",
        name: "Villalobos Impersonator",
        thumbnail_url:
          "https://fgidzl2txnqjfdnx.public.blob.vercel-storage.com/thumbnails/villalobos%20impersonator-e411ijTuUBWgrLwJxXC9lEtoLAARIV.jpeg",
      },
    ];

    setMixes(data);
    setLoading(false);
    // } else {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    if (loading) {
      fetchMixes();
    }
  }, [loading]);

  return (
    <div className={classes.minimixesPage}>
      <div className={classes.backBtnWrapper}>
        <Button
          size="large"
          icon={<LeftOutlined />}
          onClick={() => navigate("/menu")}
        />
      </div>

      {loading ? (
        <div
          style={{
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[30, 30]} style={{ padding: 40, maxWidth: 600 }}>
          {mixes.map((item, index) => (
            <Col xs={24} key={`track-${index}`} style={{ height: "20vh" }}>
              <Player
                id={`track-${index}`}
                onPlay={setCurrentTrack}
                activeTrack={currentTrack}
                thumbnailUrl={item.thumbnail_url}
                audioUrl={item.audio_url}
                name={item.name}
                peaks={item.peaks}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

const useStyle = createUseStyles({
  minimixesPage: {
    width: "100vw",
    minHeight: "100vh",
    overflowX: "hidden",
    overflowY: "scroll",
    paddingTop: 80,
    paddingBottom: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    background: "#fff",
  },
  backBtnWrapper: {
    width: "100%",
    maxWidth: 800,
    position: "absolute",
    top: 0,
    padding: 30,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
    "& .ant-btn": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
});
